import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrl: './recipes.component.css'
})
export class RecipesComponent {

  products = [];
  originalProducts = [];
  recipes = [];
  types = {
    1: "Raňajky",
    2: "Obed",
    3: "Večera",
    4: "Iné"
  };
  categories = {};
  displayStyle = "none";

  form;
  spinner: boolean = true;
  imgURL = '';
  timestamp

  constructor(private api: UserService, private fb: FormBuilder) {
    this.timestamp = (new Date()).getTime();
  }


  unsetForm() {
    this.form = this.fb.group({
      recipe_id: new UntypedFormControl(+0, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      name: new UntypedFormControl('', [
        Validators.pattern(/^[0-9a-zA-ZáÁéÉéíÍóÓöÖőŐúÚüÜűŰľĽĹšŠčČťŤžŽýÝäňŇôŔŘďĎ.,-/()°:\s]*$/),
        Validators.maxLength(200)
      ]),
      img: new UntypedFormControl('', [
        Validators.pattern(/^[0-9a-zA-ZáÁéÉéíÍóÓöÖőŐúÚüÜűŰľĽĹšŠčČťŤžŽýÝäňŇôŔŘďĎ.,-/()°:\s]*$/)
      ]),
      type: new UntypedFormControl(+2, [
        Validators.required,
        Validators.pattern(/^[1-4]*$/),
      ]),
      category: new UntypedFormControl(+1, [
        Validators.required,
        Validators.pattern(/^[1-9]*$/),
      ]),
      persons: new UntypedFormControl(+4, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(1)
      ]),
      description: new UntypedFormControl('', [
        Validators.pattern(/^[0-9a-zA-ZáÁéÉéíÍóÓöÖőŐúÚüÜűŰľĺĽĹŕŔřŘšŠčČťŤžŽýÝäňŇôŔŘďĎ.,-/()°:\s]*$/),
        Validators.maxLength(2000)
      ]),
      additionals: new UntypedFormControl('', [
        Validators.pattern(/^[0-9a-zA-ZáÁéÉéíÍóÓöÖőŐúÚüÜűŰľĺĽĹŕŔřŘšŠčČťŤžŽýÝäňŇôŔŘďĎ.,-/()°:\s]*$/),
        Validators.maxLength(2000)
      ]),
      recipe_items: this.fb.array([])
    })
  }

  ngOnInit() {
    forkJoin(
      this.api.getProductsSimple(),
      this.api.getRecipeCategories()
    ).subscribe(d => {
      this.products = d[0];
      this.originalProducts = this.products;
      this.categories = d[1];
    })
    this.unsetForm();
    this.getRecipes();
  }

  getRecipes() {
    this.api.getRecipes().subscribe(r => {
      this.recipes = r
      this.spinner = false;
    });
  }


  get recipe_items() {
    return this.form.controls["recipe_items"] as FormArray;
  }

  addIngredient() {
    const recipe_item = this.fb.group({
      recipe_id: new FormControl(+this.form.value['recipe_id'], [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      item_id: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(1)
      ]),
      quantity: new FormControl(0, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(1)
      ])
    })
    this.recipe_items.push(recipe_item);
    this.checkIfAlreadyExists();
  }

  deleteIngredient(index: number) {
    // old method
    //first we need to remove disabled tag from the ingredient
    // let i = this.products.findIndex(x => x.item_id == this.recipe_items.value[index].item_id);
    // if (i !== -1) this.products[i].disabled = false;
    //now we can remove the row
    this.recipe_items.removeAt(index);
    this.checkIfAlreadyExists();
  }

  checkIfAlreadyExists() {
    this.products = this.originalProducts;
    this.form.value['recipe_items'].forEach(v => {
      let index = this.products.findIndex(x => x.item_id == v.item_id)
      if (index !== -1) this.products.splice(index, 1);
    })
  }

  closeRecipeModal() {
    this.displayStyle = "none";
    this.spinner == true;
    console.log('should close tag modal')
    this.recipe_items.clear();
    this.imgURL = '';
    this.getRecipes();
  }

  showModal(id) {
    this.imgURL = '';
    this.spinner == true;
    if (id > 0) {
      this.api.getRecipe(id).subscribe(d => {
        this.initializeForm(d['recipe'], d['image']);
        this.displayStyle = "block";
      })
    } else {
      this.unsetForm();
      this.spinner = false;
      this.displayStyle = "block";
    }
  }

  initializeForm(data, img) {
    this.imgURL = img;
    this.form.controls['recipe_id'].setValue(data['recipe_id']);
    this.form.controls['name'].setValue(data['name']);
    this.form.controls['description'].setValue(data['description']);
    this.form.controls['additionals'].setValue(data['additionals']);
    this.form.controls['type'].setValue(data['type']);
    this.form.controls['category'].setValue(data['category']);
    this.form.controls['persons'].setValue(data['persons']);
    this.form.controls['img'].setValue(data['img']);
    data['recipe_items'].map(r => {
      const recipe_item = this.fb.group({
        recipe_id: new FormControl(+r.recipe_id, {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ]
        }),
        item_id: new FormControl(+r.item_id, {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ]
        }),
        quantity: new FormControl(r.quantity, {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ]
        })
      })
      this.recipe_items.push(recipe_item);
    })
    this.spinner = false;
    //this.checkIfAlreadyExists();
  }

  onRecipeSubmit() {
    this.spinner == true
    let req = this.form.value['recipe_id'] == 0 ? this.api.createRecipe(this.form.value) : this.api.updateRecipe(this.form.value, this.form.value['recipe_id']);
    req.subscribe(d => {
      //console.log(d);
      this.closeRecipeModal();
    })
  }

  fileSelect(event) {
    console.log('upload image')
    let file = event.target.files[0];
    if (file.size > 10000000) {
      //TODO display error
      alert("Viac ako 10M");
      return;
    }

    this.api.uploadRecipeImg(this.form.value.recipe_id, file).subscribe(res => {
      this.recipe_items.clear();
      this.showModal(this.form.value.recipe_id);
    })
  }
}
