import { Input, EventEmitter, ElementRef, Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'myautocomplete',
  imports: [],
  templateUrl: './myautocomplete.component.html',
  styleUrl: './myautocomplete.component.css',
  providers: [
    { //extending ng_value_accessor with our component - so it can be used from form?
      provide: NG_VALUE_ACCESSOR,   //what provide - the token name
      useExisting: MyautocompleteComponent, // ant what object to use for it. forwardRef allows to define it "late", at this point is places just the DatepickerComponent as a function, but will be called later, when it is defined
      multi: true //multi provider - "We have more providers, all have the same token but they provide different values"
      //useful for validators and for these accessors
    }
  ],
  host: {
    '(document:keyup)': "onKey($event)",
    '(document:click)': "onClick($event)",
  }
})
export class MyautocompleteComponent implements ControlValueAccessor {
  @Input() options;
  @Input() optionsServiceObserver;
  @Input() width;
  @Input() disabledrop;
  filteredOptions = [];
  @Input() inputType;
  @Input() placeholder;
  opened = false;
  display ="";

  replace = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ö': 'o', 'ő': 'o', 'ú': 'u', 'ü': 'u', 'ű': 'u', 'č': 'c', 'ľ': 'l', 'š': 's', 'ť': 't', 'ý': 'y', 'ä': 'a', 'ô': 'o', 'ň': 'n', 'ž': 'z' };
  regex = /[á]|[é]|[í]|[ó]|[ú]|[č]|[ž]|[ľ]|[š]|[ť]|[ý]|[ň]|[ä]|[ô]|[ö]|[ü]|[ő]|[ű]/g;

  inputText;
  get value() {
    //if we have values from backend, we need to ,,fill,, it
    if(this.inputText && !this.opened){
      let item = this.options.find(x => x.item_id == this.inputText)
      if(item) this.select(item);
      // setTimeout(() => {
      //  if(item) this.select(item);
      // },50)
    }
    return this.inputText;
  }

  ngOnInit() {
    if (this.placeholder === undefined) this.placeholder = "";
  }


  set value(value) {
    // console.log("setter: "+value)
    this.inputText = value;
    this.changed.forEach(f => f(value));
    this.openOptions();
  }

  protected changed = new Array<(value) => void>();
  protected touched = new Array<() => void>();

  //control value accessors
  writeValue(value) {
    this.inputText = value;
  }

  registerOnChange(fn: (value) => void) {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  touch() {
    this.touched.forEach(f => f());
  }

  constructor(private elementRef: ElementRef) {

  }

  openOptions() {
    if (this.disabledrop) {
      this.opened = false;
      return;
    }
    if (this.filteredOptions.length > 0)
      this.opened = true;
    else
      this.opened = false;
  }

  closeOptions() {
    this.opened = false;
  }


  updateFilter() {
    //subscribe to observable, if it is realized by it
    // we don't need this part
    // if (typeof this.options == "undefined" &&
    //   typeof this.optionsServiceObserver == "object") {
    //   console.log("fetch")
    //   let service = this.optionsServiceObserver.service;
    //   let func = this.optionsServiceObserver.function;
    //   service[func]()
    //     .subscribe(d => {
    //       this.options = d;
    //       console.log(d);
    //       this.updateFilter();
    //     });
    // }

    let filter;
    if (this.inputText.length < 2) {
      this.filteredOptions = [];
      this.closeOptions();
      return;
    }

    switch (typeof this.inputText) {
      case 'number': filter = this.inputText.toString().toLowerCase();
      //console.log('number given');
        if (typeof this.options == 'object') {
          this.filteredOptions = this.options.filter(
            (item) => {
              // console.log(item)
              // console.log(filter)
              return this.removeDia(item.item_id.toString().toLowerCase()).includes(filter);
            }
          )
        }
        else {
          this.filteredOptions = [];
        }
        break;
      case 'string': filter = this.removeDia(this.inputText.toLowerCase());
      //console.log('string given');
        if (typeof this.options == 'object') {
          this.filteredOptions = this.options.filter(
            (item) => {
              return this.removeDia(item.name.toLowerCase()).includes(filter);
            }
          )
        }
        else {
          this.filteredOptions = [];
        }
        break;
    }

    this.openOptions();
    //saby's version
    // switch ( typeof this.inputText) {
    //     case 'number': filter = this.inputText.toString().toLowerCase(); break;
    //     case 'string': filter = this.removeDia(this.inputText.toLowerCase()); break;
    // }

    // if ( typeof this.options == 'object'){
    //     this.filteredOptions = this.options.filter(
    //         (item)=>{
    //             return this.removeDia(item.toLowerCase()).includes(filter);
    //         }
    // )}
    // else {
    //     this.filteredOptions = [];
    // }
    // this.openOptions();
  }

  removeDia(text) {
    //console.log(text)
    return text.toLowerCase().replace(this.regex, (m) => { return this.replace[m] });
  }

  onKey($event) {
    const withinElement = this.elementRef.nativeElement.contains($event.target);
    if (withinElement) this.updateFilter();
    else this.closeOptions();
  }


  onClick($event) {
    const withinElement = this.elementRef.nativeElement.contains($event.target);
    if (withinElement && !this.opened) {   //is closed and clicked on it
      this.updateFilter();
    }
    else {
      this.closeOptions();
    }
  }

  select(item) {
    //console.log(item);
    //this.filterChange.emit(item);
    this.value = item.item_id;
    this.display = item.name+' '+item.quantity+item.unit;
    this.closeOptions();
  }
}
