import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsComponent } from './products/products.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { CategoriesComponent } from './categories/categories.component';
import { StoresComponent } from './stores/stores.component';
import { ProductfilterPipe } from './pipes/productfilter.pipe';
import { TagsComponent } from './tags/tags.component';
import { TagModalComponent } from './tag-modal/tag-modal.component';
import { AuthIntereptor } from './interceptors/auth.interceptor';
import { UsersComponent } from './users/users.component';
import { AlternativesComponent } from './alternatives/alternatives.component';
import { StoreModalComponent } from './store-modal/store-modal.component';
import { AltModalComponent } from './alt-modal/alt-modal.component';
import { CompareModalComponent } from './compare-modal/compare-modal.component';
import { ActionsComponent } from './actions/actions.component';
import { RecipesComponent } from './recipes/recipes.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MyautocompleteComponent } from './myautocomplete/myautocomplete.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ProductsComponent,
    LoginComponent,
    ProductEditComponent,
    CategoriesComponent,
    StoresComponent,
    ProductfilterPipe,
    TagsComponent,
    TagModalComponent,
    UsersComponent,
    AlternativesComponent,
    StoreModalComponent,
    AltModalComponent,
    CompareModalComponent,
    ActionsComponent,
    RecipesComponent,
    MyautocompleteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule
  ],
  providers: [ProductfilterPipe,{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthIntereptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
